$primary-red: rgb(185, 137, 129);
$primary-green: rgb(125, 131, 109);

$secondary-red: rgb(241, 209, 201);
$secondary-green: rgb(225, 227, 209);
$primary-dark: rgb(34, 30, 32);

$secondary-beige: rgb(229, 210, 189);
$tertiary-beige: rgb(239, 229, 215);
$primary-beige: rgb(205, 178, 151);
