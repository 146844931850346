@import url("https://fonts.googleapis.com/css2?family=Aboreto&family=Asul:wght@400;700&family=Ovo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rufina:wght@400;700&family=STIX+Two+Text:ital,wght@0,400..700;1,400..700&display=swap");
@import "./styles/partials/variables";
// Asul Ovo Poppins Rufina STIX Two Text Aboreto
* {
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
}

body {
  background-color: rgb(186, 194, 164);
  color: white;
  text-align: center;
  text-wrap: balance;
  font-family: "Aboreto";
  text-transform: uppercase;
}
// well be back soon
h1 {
  // text-transform: uppercase;
  text-shadow: 2px 1px 3px rgba(222, 224, 222, 0.5);
  font-size: 3.3rem;
  padding: 2rem 0rem 1rem 0rem;
  color: $primary-green;
  margin: 0 10px;
  font-weight: 800;
  letter-spacing: -1.6px;
  margin-top: 2rem;
}
// contact us
h2 {
  font-weight: 800;
  color: $tertiary-beige;
  font-size: 2.5rem;
}
// h3 is JKP
h3 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1rem;
  padding: 0;
  margin: 3rem 0 0 0;
  color: $secondary-green;
  font-family: "asul", sans-serif;
}

// h4 is jordanklebe
h4 {
  font-weight: 800;
  color: white;
  font-size: 1.25rem;
  line-height: 1rem;
  padding: 0;
  margin: 3rem 0 0 0;
  font-family: "asul", sans-serif;
}
// h5 is founderceo
h5 {
  font-weight: 400;
  color: $tertiary-beige;
  line-height: 1.4rem;
  font-size: 0.9rem;
  margin: 8px auto;
  border-top: 1px solid $tertiary-beige;
  width: 200px;
  font-family: "asul", sans-serif;
}
// footer
h6 {
  font-weight: 300;
  margin-top: 10rem;
}
// great things are coming
header p {
  text-wrap: pretty;
  font-size: 1.25rem;
  padding: 0;
  line-height: 1.75rem;
  font-family: "Poppins", sans-serif;
  text-transform: none;
}

// no more
// section p {
//   font-size: 1.5rem;
//   padding: 1.75rem 0;
//   font-family: "Montserrat", sans-serif;
// }

.round-logo {
  margin: 1.75rem auto;
  width: 200px; /* Adjust size as needed */
  height: 200px; /* Adjust size as needed */
  border-radius: 50%;
  object-fit: cover; /* Ensure the image covers the entire area */
  transform: rotate(20deg);
  animation: rotate-animation 20s infinite linear;
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// @media (max-width: 700px) {
//   h1 {
//     font-size: 2.25rem;
//   }
// }
/* Media query for smaller screens */
// @media (max-width: 600px) {
//   h1 {
//     font-size: 1.75rem;
//     padding-top: 3.25rem;
//   }

//   p {
//     font-size: 1.1rem;
//     line-height: 1.25rem;
//   }
//   section {
//     width: 300px;
//   }
// }

section {
  // position: relative;
  margin: 0 auto;
  width: 500px;
  height: auto;
  padding: 1rem;
  // overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  background-color: $primary-green;
}

#shadow-host-companion {
  display: none;
}
.contact-info a {
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  color: $primary-dark;
  font-family: "poppins", sans-serif;
}

.contact-info a:hover {
  color: $tertiary-beige;
}

button {
  display: flex;
  width: 330px;
  color: #fff;
  padding: 10px 20px;
  background-color: rgb(241, 239, 239);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 20px auto;
  border: 2px solid $primary-green;
  border-radius: 5px;
  justify-content: center;
}

@media screen and (max-width: 475px) {
  h1 {
    font-size: 3rem; /* Adjust font size for mobile */
    padding-top: 2.5rem; /* Adjust padding for mobile */
  }
  h2 {
    font-size: 1.8rem; /* Adjust font size for mobile */
    margin: 1rem 0; /* Adjust margins for mobile */
  }

  h3 {
    font-size: 1rem; /* Adjust font size for mobile */
    line-height: 1.2rem; /* Adjust line height for readability */
    margin: 1.5rem 0 0 0; /* Adjust margins for mobile */
  }

  h4 {
    font-size: 1rem; /* Adjust font size for mobile */
    line-height: 1.2rem; /* Adjust line height for readability */
    margin: 1.5rem 0 0 0; /* Adjust margins for mobile */
  }

  h5 {
    font-size: 0.8rem; /* Adjust font size for mobile */
    line-height: 1.2rem; /* Adjust line height for readability */
    margin: 5px auto; /* Adjust margins for mobile */
    width: 160px; /* Adjust width for mobile */
  }
  button {
    width: 275px;
  }
  section {
    width: 360px;
  }
  .contact-info a {
    font-size: 0.85rem;
  }
  header p {
    font-size: 1rem;
    margin: 1rem;
  }
}
